import React from 'react';

import { getComponentQueryKey } from '@/core/features/a-component/services/component-service';
import { useIsFetching } from '@/core/features/react-query/react-query-service';

export default function usePageComponentLoading(componentIri: string) {
    const fetches = useIsFetching({ queryKey: getComponentQueryKey(componentIri) });
    const [isLoading, setLoading] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const isFetching = fetches > 0;

    /** This way the value is initially false on the server
     * but true on the client without causing hydration errors */
    React.useEffect(() => {
        setLoading(true);
    }, []);

    React.useEffect(() => {
        if (isFetching) {
            setLoading(true);
        }
        if (!isFetching && isLoading) {
            timeoutRef.current = setTimeout(() => {
                /** Using setTimeout with a delay of 0 to defer the execution of setLoading(false)
                 * to give the component some time to re-render right after the updated component is available */
                setLoading(false);
            }, 0);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isFetching, isLoading]);

    return isLoading;
}
