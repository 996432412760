import React from 'react';

import styles from './show-more.module.scss';

type ShowMoreProps = {
    isExpanded?: boolean;
    onClick?: () => void;
    showMoreRef: React.MutableRefObject<HTMLSpanElement | null>;
};

export default function ShowMore({ isExpanded = false, onClick, showMoreRef }: ShowMoreProps) {
    return (
        <span
            className={styles.showMore}
            data-qa-id={'qa-show-more'}
            onClick={onClick}
            ref={showMoreRef}
        >
            {isExpanded ? ' weniger' : ' ... mehr'}
        </span>
    );
}
