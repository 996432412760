import type {
    PageComponent,
    PageComponentMeta,
    PageComponentType,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentConfig } from '@/features/a-dynamic-page/components/page-component-registry-type';
import type { GroupV1Meta } from '@/features/group-v1/group-v1-type';
import type { GroupComponentProps } from '@/features/group-v1/group-v1-types';

import React from 'react';

import { ErrorBoundaryWrapper } from '@/core/features';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { useDynamicPageComponentIndex } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-index';
import PageComponentDebugWrapper from '@/features/a-dynamic-page/components/debug/page-component-debug-wrapper';
import PageComponentLazyWrapper from '@/features/a-dynamic-page/components/page-component-lazy-wrapper';
import usePageComponentLoading from '@/features/a-dynamic-page/components/use-page-component-loading';

type PageComponentSwitchProps = React.PropsWithChildren<{
    component: PageComponent;
    componentConfig?: PageComponentConfig;
    componentIndex: number;
    groupComponentMeta?: GroupV1Meta;
    groupComponentProps?: GroupComponentProps;
    isPageLoading: boolean;
    isWidget: boolean;
    pageComponentNames: PageComponentType[];
}>;

export type PageComponentProps<Attributes, Meta = PageComponentMeta, ConfigProps = Object> = {
    attributes: Attributes;
    componentId: string;
    componentIndex: number;
    componentIri: string;
    componentType: PageComponentType;
    configProps?: ConfigProps;
    groupComponentMeta?: GroupV1Meta;
    groupComponentProps?: GroupComponentProps;
    imagePriority?: boolean;
    isLoading?: boolean;
    isPageLoading?: boolean;
    isWidget?: boolean;
    meta?: Meta;
    pageComponentIndex: number;
    pageComponentNames: PageComponentType[];
};

export default function PageComponentSwitch({
    children,
    component,
    componentConfig,
    componentIndex,
    groupComponentMeta,
    groupComponentProps,
    isPageLoading,
    isWidget,
    pageComponentNames,
}: PageComponentSwitchProps) {
    const componentIri = component['@id'];
    const componentType = component['@type'];
    const componentId = getComponentId(componentIri);
    const pageComponentIndex = useDynamicPageComponentIndex(componentIri);
    const isLoading = usePageComponentLoading(componentIri);

    if (componentConfig === undefined) {
        return null;
    }

    const Component = componentConfig.render;

    if (Component === null) {
        return null;
    }

    const { attributes, loading, meta } = component;
    const componentProps: PageComponentProps<typeof attributes, typeof meta> = {
        ...attributes,
        attributes,
        componentId,
        componentIndex,
        componentIri,
        componentType,
        configProps: componentConfig?.configProps,
        groupComponentMeta,
        groupComponentProps,
        imagePriority: loading === 'eager',
        isLoading,
        isPageLoading,
        isWidget,
        meta,
        pageComponentIndex,
        pageComponentNames,
    };

    return (
        <ErrorBoundaryWrapper fallbackRender={() => null}>
            <PageComponentDebugWrapper
                componentId={componentId}
                type={componentType}
            >
                <PageComponentLazyWrapper
                    componentId={componentProps.componentId}
                    componentType={componentProps.componentType}
                    lazyConfig={componentConfig.lazyConfig}
                    pageComponentIndex={pageComponentIndex}
                >
                    <Component {...componentProps}>{children}</Component>
                </PageComponentLazyWrapper>
            </PageComponentDebugWrapper>
        </ErrorBoundaryWrapper>
    );
}
