import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { removeAllSearchParams } from '@/core/utils/url';

type UseTruncationRequest = {
    components: PageComponent[];
    showMoreRef: React.MutableRefObject<HTMLSpanElement | null>;
    truncateFromId: string | undefined;
};

type UseTruncationResponse = {
    isExpanded: boolean;
    renderUntilIndex: null | number;
    showMoreDefaultButton: boolean;
    toggleExpanded: () => void;
};

export default function useTruncation({
    components,
    showMoreRef,
    truncateFromId,
}: UseTruncationRequest): UseTruncationResponse {
    const [isExpanded, setExpanded] = React.useState(false);
    const showMoreParent = showMoreRef.current?.parentElement;
    const truncationIndexOfId = React.useMemo((): null | number => {
        if (!truncateFromId) {
            return null;
        }

        const truncateFromIndex = components.findIndex((component) =>
            component['@id'].startsWith(removeAllSearchParams(truncateFromId)),
        );

        return truncateFromIndex === -1 ? null : truncateFromIndex;
    }, [components, truncateFromId]);

    const renderUntilIndex = (() => {
        if (truncationIndexOfId === null) {
            return null;
        }
        if (isExpanded) {
            return components.length - 1;
        }

        return truncationIndexOfId - 1;
    })();

    const toggleExpanded = () => {
        if (isExpanded) {
            if (!(showMoreParent instanceof HTMLElement)) {
                return;
            }

            showMoreParent.scrollIntoView(true);
            // Needed for adjustment of scroll position
            window.scrollBy(0, -20);
        }
        setExpanded((value) => !value);
    };
    const componentType = renderUntilIndex !== null ? components[renderUntilIndex]?.['@type'] : null;
    const showMoreDefaultButton = componentType !== null && componentType !== 'TextV1';

    return {
        isExpanded,
        renderUntilIndex,
        showMoreDefaultButton,
        toggleExpanded,
    };
}
