import type {
    PageComponent,
    PageComponentType,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { GroupV1Meta } from '@/features/group-v1/group-v1-type';
import type { GetComponentConfig } from '@/features/group-v1/group-v1-types';

import React from 'react';

import ShowMore from '@/core/components/show-more/show-more';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import { classnames } from '@/core/utils/bem-classname';
import { MapBottomSheetV1 } from '@/features/_index/mobile';
import PageComponentSwitch from '@/features/a-dynamic-page/components/page-component-switch';
import useTruncation from '@/features/group-v1/use-truncation';

import styles from './group-v1.module.scss';

const GroupComponentStyleMapper: Record<GroupV1Meta['style'], string> = {
    content_box: 'content-box width-100 ',
    content_box_border: 'content-box-border width-100 ',
    full_width: 'full-width padding-x-10',
    map_bottom_sheet: '',
    no_gaps: 'no-gap width-100',
    titled_border_box: styles.titledBorderBox,
};

type GroupComponentContentProps = {
    componentId: string;
    components: PageComponent[];
    getComponentConfig: GetComponentConfig;
    htmlTitle: null | string;
    isPageLoading: boolean;
    isWidget: boolean;
    meta: GroupV1Meta;
    pageComponentNames: PageComponentType[];
    title: null | string;
};

const GroupV1_ = ({
    componentId,
    components,
    getComponentConfig,
    htmlTitle,
    isPageLoading,
    isWidget,
    meta,
    pageComponentNames,
    title,
}: GroupComponentContentProps) => {
    const showMoreRef = React.useRef<HTMLSpanElement | null>(null);
    const { isExpanded, renderUntilIndex, showMoreDefaultButton, toggleExpanded } = useTruncation({
        components,
        showMoreRef,
        truncateFromId: meta.truncateFrom ?? undefined,
    });

    const isClient = useClientOnlyRender();

    if (meta.style === 'map_bottom_sheet') {
        if (!isClient) {
            return null;
        }
        return (
            <MapBottomSheetV1
                componentId={componentId}
                components={components}
                getComponentConfig={getComponentConfig}
                groupComponentMeta={meta}
                htmlTitle={htmlTitle}
                isPageLoading={isPageLoading}
                pageComponentNames={pageComponentNames}
            />
        );
    }

    const topRightAbsoluteComponents = components.filter(
        (component) => component.meta.layoutPosition === 'top_right_absolute',
    );
    const otherComponents = components.filter((component) => component.meta.layoutPosition !== 'top_right_absolute');
    const isFullWidth = meta.style === 'full_width';
    const hasContentBox = meta.style === 'content_box' || meta.style === 'content_box_border';
    const pageComponentNamesOtherComponents = otherComponents.map((component) => {
        return component['@type'];
    });
    const pageComponentNamesTopRightAbsoluteComponents = topRightAbsoluteComponents.map((component) => {
        return component['@type'];
    });

    return (
        <div
            className={classnames('flex-column gap-15 wrap', GroupComponentStyleMapper[meta.style], styles.wrapper, {
                'margin-top-5': meta.style === 'content_box' && title,
            })}
            id={`c_${componentId}`}
            //eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ scrollMarginTop: title && meta.style === 'titled_border_box' ? '30px' : undefined }}
        >
            {title && (
                <h3
                    className={classnames({
                        [styles.borderBoxTitle]: meta.style === 'titled_border_box',
                        [styles.contentBoxTitle]: meta.style === 'content_box',
                    })}
                >
                    {title}
                </h3>
            )}
            {otherComponents.map((component, index) => {
                if (renderUntilIndex !== null && index > renderUntilIndex) {
                    return null;
                }

                const componentType = component['@type'];
                const componentConfig = getComponentConfig(componentType);

                const showMoreButton = !showMoreDefaultButton && renderUntilIndex === index;

                return (
                    <PageComponentSwitch
                        component={component}
                        componentConfig={componentConfig}
                        componentIndex={index}
                        groupComponentMeta={meta}
                        groupComponentProps={{
                            hasContentBox,
                            isFullWidth,
                            isLastGroupItem: index === components.length - 1,
                        }}
                        isPageLoading={isPageLoading}
                        isWidget={isWidget}
                        key={`${getComponentId(component['@id'])}-${index}`}
                        pageComponentNames={pageComponentNamesOtherComponents}
                    >
                        {showMoreButton && (
                            <ShowMore
                                isExpanded={isExpanded}
                                onClick={toggleExpanded}
                                showMoreRef={showMoreRef}
                            />
                        )}
                    </PageComponentSwitch>
                );
            })}
            {topRightAbsoluteComponents.length > 0 && (
                <div className={styles.topRightAbsoluteWrapper}>
                    {topRightAbsoluteComponents.map((component, index) => {
                        return (
                            <PageComponentSwitch
                                component={component}
                                componentConfig={getComponentConfig(component['@type'])}
                                componentIndex={index}
                                isPageLoading={isPageLoading}
                                isWidget={isWidget}
                                key={`${getComponentId(component['@id'])}-${index}`}
                                pageComponentNames={pageComponentNamesTopRightAbsoluteComponents}
                            />
                        );
                    })}
                </div>
            )}

            {showMoreDefaultButton && (
                <ShowMore
                    isExpanded={isExpanded}
                    onClick={toggleExpanded}
                    showMoreRef={showMoreRef}
                />
            )}
        </div>
    );
};

export default React.memo(GroupV1_);
