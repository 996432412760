import { useAtom } from '@/core/features/store/atom-store';
import { atom } from '@/core/features/store/atom-store';
import color from '@/core/features/styles/color';

export const componentDebugAtom = atom(false);

export default function ComponentDebuggerWrapper() {
    const [isComponentDebug, setComponentDebug] = useAtom(componentDebugAtom);

    return <Icon onClick={() => setComponentDebug(!isComponentDebug)} />;
}

const Icon = ({ onClick }: { onClick: () => void }) => (
    <svg
        fill={'none'}
        height={'40'}
        onClick={onClick}
        viewBox={'0 0 48 48'}
        width={'40'}
        xmlns={'http://www.w3.org/2000/svg'}
    >
        <path
            d={'M17 12L24 5L31 12L24 19L17 12Z'}
            fill={color('anakiwa')}
            stroke={color('mineShaft')}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'4'}
        />
        <path
            d={'M17 36L24 29L31 36L24 43L17 36Z'}
            fill={color('anakiwa')}
            stroke={color('mineShaft')}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'4'}
        />
        <path
            d={'M29 24L36 17L43 24L36 31L29 24Z'}
            fill={color('anakiwa')}
            stroke={color('mineShaft')}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'4'}
        />
        <path
            d={'M5 24L12 17L19 24L12 31L5 24Z'}
            fill={color('anakiwa')}
            stroke={color('mineShaft')}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'4'}
        />
    </svg>
);
